import { Flex, Divider, useColorModeValue, useMediaQuery } from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';

// import Settings from './settings/Settings';
import SettingsColorTheme from './settings/SettingsColorTheme';
import SwapButton from './SwapButton';
import TopBarStats from './TopBarStats';

const feature = config.features.swapButton;

const TopBar = () => {
  const bgColor = useColorModeValue('gray.50', 'whiteAlpha.100');
  const [ isMobile ] = useMediaQuery('(max-width: 999px)');

  return (
    <Flex
      py={ isMobile ? 2 : 'none' }
      px={ isMobile ? 6 : 'none' }
      justifyContent="space-between"
      alignItems="center"
      bgColor={ isMobile ? "#fff" : bgColor }
      borderBottom={ isMobile ? '1px solid #E2E8F0' : 'none' }
      width={ isMobile ? '80%' : '100%' }
      margin={ isMobile ? 'auto' : '0' }
    >
      {/* <TopBarStats/> */}
      {isMobile && <TopBarStats/>}
      <Flex alignItems="center">
        { feature.isEnabled && (
          <>
            <SwapButton/>
            <Divider mr={ 3 } ml={{ base: 2, sm: 3 }} height={ 4 } orientation="vertical"/>
          </>
        ) }
        <SettingsColorTheme/>
      </Flex>
    </Flex>
  );
};

export default React.memo(TopBar);
